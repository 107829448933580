import { debounce } from 'lodash'

import * as servicesPerson from '@/modules/Chat/components/conversation/services/person'
import { sidebarTabs } from '@/modules/Chat/components/sidebar/enums/sidebar-tabs'
import { enums } from '@/modules/Chat/components/sidebar/services/enums'
import { inboxLists } from '@/modules/Chat/components/sidebar/services/inbox'
import {
  soundNotification,
  stopAllNotifications,
  titleNotification
} from '@/modules/Chat/helpers/browser-notifications'
import httpStatusCodes from '@/modules/Chat/helpers/http-status-codes'
import InitialChatPayload from '@/modules/Chat/helpers/initial-chat-payload'
import IntegratorNames from '@/modules/Chat/helpers/integrator-names'
import phones from '@/modules/Chat/helpers/phones'
import roomStatus from '@/modules/Chat/helpers/room-status'
import * as agentService from '@/modules/Chat/services/agent'
import * as chatService from '@/modules/Chat/services/chat'
import * as groupService from '@/modules/Chat/services/groups'
import * as integratorService from '@/modules/Chat/services/integrator'
import * as settingsService from '@/modules/Chat/services/settings'
import * as whatsappService from '@/modules/Chat/services/whatsapp'

import i18n from '@/common/i18n'

import * as types from './mutations-types'

const TIME_BETWEEN_BROWSER_NOTIFICATION = 500 // .5s
function getIntegratorNameByNumber(context, number) {
  const whatsappNumber = context.getters.whatsappNumbers.find(
    w =>
      w.number.toString().replace(/\D/g, '') ===
      number.toString().replace(/\D/g, '')
  )

  if (!whatsappNumber) throw new Error(`${number} not found as valid whatsapp`)

  whatsappNumber.integrator = whatsappNumber.integrator || 'octadesk'

  return whatsappNumber.integrator
}

function getWhatsAppChannelByNumber(context, number) {
  const integratorName = getIntegratorNameByNumber(context, number)

  const integrator = context.getters.integrators.find(
    i => integratorName === i.name
  )

  return integrator.channels.find(c => c.name === 'whatsapp')
}

const buildNewRoom = payload => {
  const { user, channel, domainFrom, messages, customFields } = payload

  const room = {
    channel,
    user,
    domainFrom,
    tags: ['proactive'],
    customFields,
    messages: messages || []
  }

  if (payload.organizations) {
    room.user.organizations = payload.organizations

    room.organizations = payload.organizations
  }

  if (payload.clientLastMessageDate) {
    room.clientLastMessageDate = payload.clientLastMessageDate
  }

  return room
}

function openRoom(context, room) {
  const { chatKey } = context.getters

  if (chatKey !== room?.key) {
    context.dispatch('setChatKey', { key: room.key })
  }

  refreshTab(context)
}

function refreshTab(context) {
  const tab = sidebarTabs[0]
  const currentStatus = inboxLists['all'][tab.id]
  context.dispatch('setStatus', currentStatus)

  context.dispatch('setConversationsListType', enums.listTypes.open)

  context.dispatch('setSelectedSidebarTab', tab)
}

export const setFetchedInitialData = (context, hasFetchedInitialData) => {
  context.commit(types.SET_FETCHED_INITIAL_DATA, hasFetchedInitialData)
}

export const setAgentStatus = async (context, status) => {
  const agent = await agentService.setAgentStatus(status)

  context.commit(types.SET_AGENT, agent)

  return agent
}

export const getOnlineAgents = async context => {
  const onlineAgents = await agentService.getOnlineAgents()

  context.commit(types.SET_ONLINE_AGENTS, onlineAgents)

  return onlineAgents
}

export const getAgents = async context => {
  const agents = await agentService.getAgents()

  context.commit(types.SET_AGENTS, agents)

  return agents
}

export const getGroups = async context => {
  const groups = await groupService.getGroups()

  context.commit(types.SET_GROUPS, groups)

  return groups
}

export const getRestrictedGroups = async context => {
  const groups = await groupService.getRestrictedGroups()

  context.commit(types.SET_RESTRICTED_GROUPS, groups)

  return groups
}

export const getAgent = async context => {
  const { userLogged } = context.rootGetters

  if (!userLogged?.displayName) {
    const agent = await agentService.getAgent()
    userLogged.displayName = agent?.displayName
  }

  context.commit(types.SET_AGENT, userLogged)

  return userLogged
}

export const setAgentNotificationEnabled = (context, value) => {
  context.commit(types.SET_SETTINGS_NOTIFY_AGENTS, value)
}

export const setConnectSocketStatus = (context, connectedSocket) =>
  context.commit(types.SET_CONNECTED, connectedSocket)

export const openTicket = (_, ticketNumber) => {
  window.open(`/ticket/edit/${ticketNumber}/`, '_blank')
}
export const startNotification = debounce(function (context, message) {
  if (context.getters?.settings?.conversation?.notifyAgents?.enabled) {
    soundNotification()
    titleNotification(message)
  }
}, TIME_BETWEEN_BROWSER_NOTIFICATION)

export const stopNotification = () => stopAllNotifications()

// not remove prefix 'socket'
export const socketAgentConnectionStatus = (context, agentStatus) => {
  if (context.getters.agentId === agentStatus.idAgent) {
    context.commit(types.SET_AGENT_STATUS, agentStatus.status)
  }
}

// analytics start
export const createInitialChat = async context => {
  const chatsCount = await context.dispatch('getAllChatsCount')

  if (chatsCount === 0) {
    const owners = await context.dispatch('getOwners')

    const greetings =
      owners && owners.length >= 1 && owners[0].name
        ? `Olá ${owners[0].name}!\n`
        : ''

    const createdChat = await chatService.createChat(
      InitialChatPayload(greetings)
    )

    if (createdChat) {
      await context.dispatch('appendChat', createdChat)

      await context.dispatch('setChat', createdChat.key)

      await context.dispatch('setChatKey', { key: createdChat.key })
    }
  }
}

export const createNewChat = async (context, payload) => {
  const room = buildNewRoom(payload)

  const createdChat = await chatService.createChat(room)

  if (!createdChat) return false

  if (createdChat.status !== roomStatus.talking)
    await chatService.changeStatus(createdChat.key, roomStatus.talking)

  openRoom(context, createdChat)

  return true
}

export const createWhatsappChat = async (context, contact) => {
  const clientNumber = '+' + contact.clientNumber.toString().replace(/\D/g, '')

  const domainFrom = '+' + contact.agentNumber.toString().replace(/\D/g, '')

  const phoneContact = phones.parse(clientNumber) || {
    nationalNumber: clientNumber.toString().replace('+55', '')
  }

  const integratorName = getIntegratorNameByNumber(context, domainFrom)

  const chat = {
    ...contact,
    channel: 'whatsapp',
    user: {
      id: contact.id,
      email: contact.email,
      name: contact.name,
      phoneContacts: [
        {
          type: 1,
          number: phoneContact.nationalNumber,
          countryCode: phoneContact.countryCallingCode
        }
      ]
    },
    domainFrom,
    customFields: [
      {
        integrator: {
          integrator: integratorName,
          from: {
            number: clientNumber
          },
          to: {
            number: domainFrom
          }
        }
      }
    ]
  }

  const payload = buildNewRoom(chat)

  const { status, data } = await chatService.createUserChatByNumbers(
    clientNumber,
    domainFrom,
    payload
  )

  if (data) {
    if (status === httpStatusCodes.CREATED) {
      await chatService.changeStatus(data.key, roomStatus.talking)
    } else if (status === httpStatusCodes.FORBIDDEN)
      return context.dispatch(
        'dispatchAlert',
        {
          body: i18n?.t(
            'sidebar.list.contacts.newChat.inconsistencies.chatExistsForbiddenToAgent'
          ),
          variant: 'danger'
        },
        { root: true }
      )

    openRoom(context, data)

    return true
  }

  return false
}

export const tryCreateWhatsappChat = async (context, contact) => {
  const whatsAppChannel = getWhatsAppChannelByNumber(
    context,
    contact.agentNumber
  )

  const whatsappNumber = context.getters.whatsappNumbers.find(
    w =>
      w.number.toString().replace(/\D/g, '') ===
      contact.agentNumber.toString().replace(/\D/g, '')
  )

  if (
    (whatsAppChannel.proactive && whatsAppChannel.proactive.isEnabled) ||
    IntegratorNames.WhatsAppApis.includes(whatsappNumber?.integrator)
  ) {
    return context.dispatch('createWhatsappChat', contact)
  }
}

export const openTemplateMessageModel = context => {
  context.commit(types.SET_SELECT_TEMPLATE_MESSAGE, context.getters.currentChat)
}

export const getAllChatsCount = async context => {
  const count = await chatService.getAllChatsCount()

  context.commit(types.SET_ALL_CHATS_COUNT, count)

  return count
}

export const getOwners = async context => {
  const owners = await servicesPerson.getOwners()

  context.commit(types.SET_OWNERS, owners)

  return owners
}

export const createRoomFromAnother = async (context, roomKey) => {
  const createdRoom = await chatService.createRoomFromAnother(
    roomKey,
    context.getters?.agent?.id
  )

  if (!createdRoom) {
    throw new Error('Room not created')
  }

  context.dispatch('setChatKey', { key: createdRoom.key })

  context.dispatch('setSelectedSidebarTab', sidebarTabs[0])
}

export const getUserOpenedChat = async (context, key) => {
  const room = await chatService.getUserOpenedChat(key)

  if (room) {
    openRoom(context, room)

    return true
  }

  return false
}

export const setChatIntegrators = async context => {
  const integrators = await integratorService.getChatIntegrators()

  context.commit(types.SET_INTEGRATORS, integrators)
}

export const getIntegratorWhatsappNumbers = async context => {
  const numbers = await integratorService.getIntegratorWhatsappNumbers()

  context.commit(types.SET_INTEGRATOR_WHATSAPP_NUMBERS, numbers)

  return numbers
}

export const getWhatsappNumbers = async context => {
  const numbers = await whatsappService.getWhatsappNumbers()

  context.commit(types.SET_WHATSAPP_NUMBERS, numbers)

  return numbers
}

export const getRestrictedWhatsappNumbers = async context => {
  const restrictedNumbers = await whatsappService.getRestrictedWhatsappNumbers()

  context.commit(types.SET_RESTRICTED_WHATSAPP_NUMBERS, restrictedNumbers)

  return restrictedNumbers
}

export const setAgentAvailability = async (context, availability) => {
  await context.dispatch('setAgentStatus', availability ? 0 : 1)

  const agent = await agentService.setAgentAvailability(availability)

  context.commit(types.SET_AGENT, agent)

  return agent
}

export const getSettings = async context => {
  const settings = await settingsService.getSettings()

  context.commit(types.SET_SETTINGS, settings)

  return settings
}

export const setResponsive = (context, responsive) => {
  context.commit(types.SET_RESPONSIVE, responsive)

  if (
    context.rootGetters['chat/currentWhitelabelAside'] &&
    Object.hasOwn(responsive, 'showMobileUserInformations')
  ) {
    const aside = responsive.showMobileUserInformations
      ? undefined
      : context.rootGetters['chat/currentWhitelabelAside']

    context.dispatch('chat/setCurrentChatAside', aside, { root: true })
  }
}
