import { WozAgent, WOZAgentsState } from './interfaces'

export const wozAgents = ({ agents }: WOZAgentsState) => agents

export const loadingAgents = ({ loadingAgents }: WOZAgentsState) =>
  loadingAgents

export const currentAgent = ({ currentAgent }: WOZAgentsState) => currentAgent

export const defaultAgent = ({ defaultAgent }: WOZAgentsState) => defaultAgent

export const copilotAgent = ({ copilotAgent }: WOZAgentsState) => copilotAgent

export const currentTab = ({ currentTab }: WOZAgentsState) => currentTab

export const requiredFieldsFilled = ({ currentAgent }: WOZAgentsState) => {
  if (!currentAgent) return false

  const requiredFields: (keyof WozAgent)[] = [
    'name',
    'pronoums',
    'messageSize',
    'messageNumber'
  ]

  return requiredFields.every(
    field =>
      currentAgent[field] !== undefined &&
      currentAgent[field] !== null &&
      currentAgent[field] !== ''
  )
}
